$(()=> {
    const $select = $(".js-double-select");

    let prevSelect = null

    $.each($select, (i, elem) => {
        const select = $(elem)
        const name = select.attr("data-name")

        select.multipleSelect({
            minimumCountSelected: 1,
            placeholder: name,
            multipleWidth: "100%",
            maxHeight: 200,
            width: "100%",
            onAfterCreate: () => {
                select.multipleSelect("uncheckAll")
                select.next().find(".ms-choice > span").text(name)
            },
            onClose: (e) => {
                const value = select.val()
                if (!value) {
                    select.next().removeClass("is_fill")
                }
                if (prevSelect === name) {
                    select.parents().closest(".filter__select").removeClass("is_open")
                }
            },
            onOpen: () => {
                select.parents().closest(".filter__select").addClass("is_open")
                select.next().addClass("is_fill")
                prevSelect = name
            },
            onBlur: () => {
                select.parents().closest(".filter__select").removeClass("is_open")
            },
            onClick: () => {
                select.next().addClass("is_fill")
            }
        })
    })
})