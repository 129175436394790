import { offsetDots } from "../../../js/global/global";

$(() => {
    const $slider = $(".js-gallery-top");
    const $sliderThumb = $(".js-gallery-thumb");
    const $thumbsWrap = $(".js-thumbs");

    const arrows = !!$slider.length && $slider.data("arrows").split(",")
    
    const buttonsTemplate = {
        prev : 
        `
            <button type="button" class="slick-prev gallery__arrow">
                <svg>
                    <use xlink:href="${arrows[0]}"></use>
                </svg>
            </button>
        `,

        next: 
        `
            <button type="button" class="slick-next gallery__arrow">
                <svg>
                    <use xlink:href="${arrows[1]}"></use>
                </svg>
            </button>
        `
    }

    $slider.on('init', function(event, slick) {
        offsetDots(0, $slider);
    });

    $slider.on("beforeChange", (e, slick, prevIdx, currentIdx)=> {
        $sliderThumb.removeClass("slick-current")
        $sliderThumb.eq(currentIdx).addClass("slick-current")
        
        offsetDots(currentIdx, $slider)
    })

    $slider.slick({
        infinite: false,
        slidesToShow: 1,
        prevArrow: buttonsTemplate.prev,
        nextArrow: buttonsTemplate.next,
        
        responsive: [
            {
              breakpoint: 1600,
              settings: {
                asNavFor: '.js-thumbs',
              },
            },

            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    dots: true
                }
            },
        ],
    })

  

    $thumbsWrap.slick({
        slidesToShow: 5,
        arrows: false,
        focusOnSelect: true,
        asNavFor: '.js-gallery-top',
        responsive: [
            {
              breakpoint: 1600,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              },
            },

            {
                breakpoint: 768,
                settings: "unslick",
            },
        ],
    })

    let isMobile = false
    const $imageGallery = $('[data-fancybox="gallery"]');
    const options = {
        thumbs : {
            autoStart : true
        }
    }

    $imageGallery.fancybox(options);

    $(window).on("resize load", function() {
        const width = $(this).width();

        if (width < 768 && !isMobile) {
            $imageGallery.fancybox({
                thumbs : {
                    autoStart : false
                }
            });
            isMobile = true
        } else if (width > 768 && isMobile) {
            $imageGallery.fancybox(options);
            isMobile = false
        }
    })

})

  




