$(()=> {
	function pad (str, max) {
		str = str.toString();
		return str.length < max ? pad("0" + str, max) : str;
	}

	function empty(mixed_var) {
		return (
			mixed_var === "" ||
			mixed_var === 0 ||
			mixed_var === "0" ||
			mixed_var === null ||
			mixed_var === false ||
			mixed_var === undefined ||
			mixed_var.length === 0 ||
			(mixed_var instanceof Array && mixed_var.length === 0)
		);
	}

	function ScrollToElement($, element, speed, offset = 0, callback) {
		callback = callback || function () {
		};
		$('body, html').animate({
			scrollTop: element.offset().top + offset
		}, speed, 'swing', function () {
			callback();
		});
	}

	function numberWithSpaces(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	}

	function CalcLeasing(type) {
		type = parseInt(type);
		var calc = type ? $('form[name="operative_leasing_form"]') : $('form[name="finance_leasing_form"]');
		var price = $('input[name="price"]', calc).val();
		var period = $('input[name="period"]:checked', calc).val();
		var down = $('input[name="deposit"]:checked', calc).val();
		var residual = type ? $('input[name="remaning"]:checked', calc).val() : 0;
		$.post('/leasing-ajax-calc', {type: type, price: price, period: period, down: down, residual: residual}, function(data){
			var res = JSON.parse(data);
			$('.payment', calc).text(numberWithSpaces(res.monthly))
			$('.down-payment', calc).text(numberWithSpaces(res.payment))
			$('.leasing-period', calc).text(period*12);
			$('.leasing-payment', calc).text(down*100);
			$('.leasing-remaining', calc).text(residual*100);
			if (type) {
				$('#operative_leasing_modal input[name="monthly_payment"]').val(res.monthly)
				$('#operative_leasing_modal input[name="leasing_period"]').val(period)
				$('#operative_leasing_modal input[name="initial_sum"]').val(res.payment)
				$('#operative_leasing_modal input[name="initial_percent"]').val(down)
				$('#operative_leasing_modal input[name="remaining_percent"]').val(residual)
			} else {
				$('#finance_leasing_modal input[name="monthly_payment"]').val(res.monthly)
				$('#finance_leasing_modal input[name="leasing_period"]').val(period)
				$('#finance_leasing_modal input[name="initial_sum"]').val(res.payment)
				$('#finance_leasing_modal input[name="initial_percent"]').val(down)
			}
		})
	}

	function FormValidate($, _form, is_scroll, _class) {
		var is_val = true,
			_t, _val, _itm, _itm_wrap, _vem, _scroll_element = undefined,
			rq_fields = _form.find('.required').length;

		_class = _class || 'has-error';
		_form.find('.required, input, textarea, select').removeClass(_class);

		if (rq_fields > 0) {
			for (var i = 0; i < rq_fields; i++) {
				_itm_wrap = _form.find('.required').eq(i);
				_itm = _itm_wrap.find('input, textarea, select');

				_itm.each(function () {
					_t = $(this);
					if (_t !== null && _t !== undefined && _t.attr('name') !== null && _t.attr('name') !== undefined) {
						_val = _t.val() + "";
						if (_val !== null) {
							_val = _val.replace(/^\s+/, "");
						}
						if (_val === 'null' || _val === null || _val === undefined) {
							_val = '';
						}
						if (_t.attr('name') === 'email') {
							_vem = _t;
							_vem.removeClass('error');
							$.post({ url: '/validation.php', async:false }, { val: _vem.val(), type: 'email' }, function(res){
								res = parseInt(res);
								if(!res) {
									if (!_scroll_element) {
										_scroll_element = _vem;
									}
									if (is_val) {
										_vem.focus();
									}
									_itm_wrap.addClass(_class);
									is_val = false;
									return false;
								}
							})
						} else if(_t.attr('name') === 'vin'){
							_vem = _t;
							$.post({ url: '/validation.php', async:false }, { val: _vem.val(), type: 'vin' }, function(res){
								res = parseInt(res);
								if(!res) {
									if (!_scroll_element) {
										_scroll_element = _itm_wrap;
									}
									if (is_val) {
										_vem.focus();
									}
									_itm_wrap.addClass(_class);
									is_val = false;
									return false;
								}
							})
						} else if (_t.attr('type') === 'checkbox') {
							if (!_t.is(':checked')) {
								if (!_scroll_element) {
									_scroll_element = _itm_wrap;
								}
								if (is_val) {
									_t.focus();
								}
								_itm_wrap.addClass(_class);
								is_val = false;
								return false;
							}
						} else {
							if (_val === '') {
								if (!_scroll_element) {
									_scroll_element = _itm_wrap;
								}
								if (is_val) {
									_t.focus();
								}
								_t.val('');
								_itm_wrap.addClass(_class);
								is_val = false;
								return false;
							}
						}
					}
				});
			}
			if ((is_scroll !== null) && (is_scroll) && (_scroll_element)) {
				ScrollToElement($, _scroll_element, 300);
			}
		}
		return is_val;
	}

    function reachGoalGA(goal) {
		dataLayer.push({event: 'all-form'})
        if (typeof(goal) !== undefined && goal !== null) {
            dataLayer.push({event: goal})
        }
    }

	function SendFormAjax($, _f, _callback, _type) {
		_callback = _callback || function () {};

		var _data = new FormData(_f[0]);

		$.ajax({
			url: _f.attr('action') || _ajax,
			dataType: _type || 'json',
			type: _f.attr('method') || 'POST',
			contentType: false,
			processData: false,
			data: _data,
			success: function (data) {
				_callback(data);
				reachGoalGA(_data.get('page_path'));
				initCaptcha();
				setInterval(initCaptcha,120000);
			},
			error: function (data) {
				console.log(data);
			}
		});
	}

	function getCookie(name) {
		var matches = document.cookie.match(new RegExp(
			"(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
		));
		return matches ? decodeURIComponent(matches[1]) : undefined;
	}

	function ResoteFavourites($) {
		var _cpm_text = getCookie('favourites' + _lang) || false,
			_cmp = (_cpm_text !== false) ? (_cpm_text.split('|') || '') : new Array();
		if (!empty(_cmp)) {
			$('.header .favorite__link').addClass('active')
			for (var _i in _cmp) {
				$('.add_to_favourites[data-id="' + _cmp[_i] + '"]').addClass('active');
			}
		} else {
			$('.header .favorite__link').removeClass('active')
		}
	}

	function AddToFavourites($, _id) {
		var _cpm_text = getCookie('favourites' + _lang) || false,
			_cmp = (_cpm_text !== false) ? (_cpm_text.split('|') || '') : new Array(),
			_f, _i,
			_cbb = $('.favorite_link .count');

		if (_cmp === '') {
			_cmp = [_id];
		} else {
			_f = false;
			for (_i in _cmp) {
				if (_cmp[_i] === _id) {
					_f = true;
					_cmp.splice(_i, 1);
					break;
				}
			}
			if (!_f) {
				_cmp.push(_id);
			}
		}

		_cbb.attr('data-count', _cmp.length).text(_cmp.length);

		if (_cmp.length > 0) {
			$('.header .favorite__link').addClass('active');
			//$('.header__favourite-counter').text('(' + _cmp.length + ')');
		} else {
			$('.header .favorite__link').removeClass('active');
			//$('.header__favourite-counter').text('');
		}

		_cpm_text = !empty(_cmp) ? _cmp.join('|') : '';

		document.cookie = 'favourites' + _lang + '=' + _cpm_text + '; path=/; max-age=31540000';

		$.ajax({
			type: "POST",
			url: _lang+"/favourites"
		});

	}

	function SendAjax(_url, _data, _callback, _type, _dataType) {
		_callback = _callback || function () {
		};
		if (_type === null) {
			_type = 'POST';
		}
		if (_dataType === null) {
			_dataType = 'json';
		}
		jQuery.ajax({
			url: _url,
			dataType: _dataType,
			type: _type,
			data: _data,
			cache: false,
			success: function (data) {
				_callback(data);
				mobilePaginationHide();
			},
			error: function (data) {
				console.log(data);
			}
		});
	}

	function mobilePaginationHide() {
		var _act = parseInt($('.pagination__item .active').attr('data-active'));
		$('.pagination__item').each(function () {
			if (
				_act - parseInt($('a', this).attr('data-paginate')) > 2 ||
				parseInt($('a', this).attr('data-paginate')) - _act > 2
			) {
				$(this).addClass('hidden_mobile');
			} else {
				$(this).removeClass('hidden_mobile');
			}
		});
	}

	function AddToGet(link, val, type, sub_type) {
		var _link = link || location.href,
			_new_link = '',
			_val = (/^[\?\&]/.test(val)) ? val.substr(1) : val,
			_type = type || "insert",
			_sub_type = sub_type || "show",
			_data = new Object(),
			_pr = new Object(),
			_new = new Object(),
			_all = new Object(),
			_dk,
			_before,
			_after;

		if (_val.indexOf("&") > 0) {
			_data = _val.split("&");
		} else {
			_data[0] = _val;
		}
		for (_dk in _data) {
			_pr = _data[_dk].split("=");
			_new[_pr[0]] = _pr[1];
		}
		if (_link.indexOf("?") > 0) {
			_pr = _link.split("?");
			_before = _pr[0];
			_after = _pr[1];

			_data = {};
			if (_after.indexOf("&") > 0) {
				_data = _after.split("&");
			} else {
				_data[0] = _after;
			}
			for (_dk in _data) {
				_pr = _data[_dk].split("=");
				_all[_pr[0]] = _pr[1];
			}
		} else {
			_before = _link;
		}
		for (_dk in _new) {
			if (_dk === "start") {
				_all[_dk] = 0;
			}
			if (_type === 'insert') {
				_all[_dk] = _new[_dk];
			} else if (_type === 'add') {
				if (!empty(_all[_dk])) {
					if (_all[_dk].indexOf(_new[_dk]) !== -1) {
						_all[_dk] = _all[_dk].replace(_new[_dk], '').replace(/(?:^,)|(?:,$)|(?:,+)/, '');
					} else {
						_all[_dk] += ',' + _new[_dk];
					}
				} else {
					_all[_dk] = _new[_dk];
				}
			}
		}
		_new_link = _before + "?";
		for (_dk in _all) {
			if (_new_link === _before + "?") {
				if (_dk) {
					_new_link += _dk + "=" + _all[_dk];
				}
			} else {
				_new_link += "&" + _dk + "=" + _all[_dk];
			}
		}
		if (_sub_type === 'show') {
			location.href = _new_link;
		} else if (_sub_type === 'return') {
			return _new_link;
		}
	}

	function GetFormData(_f, _type) {
		var _data = new Object(), _t;
		_type = _type || 'insert';
		_f.find('input, textarea, select').each(function () {
			_t = jQuery(this);

			if (navigator.appVersion.match(/MSIE 9/)) {
				_t.val() === _t.attr('placeholder') ? _t.val('') : '';
			}

			if (!empty(_t.attr('name'))) {
				if (_t.attr('type') === 'radio') {
					if (_t.is(':checked')) {
						_data[_t.attr('name')] = _t.val();
					}
				} else if (_t.attr('type') === 'checkbox') {
					if (_t.is(':checked')) {
						if (_type === 'insert') {
							_data[_t.attr('name')] = _t.val();
						} else if (_type === 'add') {
							if (empty(_data[_t.attr('name')])) {
								_data[_t.attr('name')] = _t.val();
							} else {
								_data[_t.attr('name')] += ',' + _t.val();
							}
						}
					}
				} else {
					_data[_t.attr('name')] = _t.val();
				}
			}
		});
		return _data;
	}

	function RestoreFilterData($) {

		function setModelByMark(_arr) {
			$('.filter').find('.js-mark-check').hide(0);
			_arr.forEach(function(val, i, _arr){
				$('.filter').find('.js-mark-' + val).show(0);
			})
		}

		if (typeof (_filter_data) !== undefined && !empty(_filter_data)) {
			var
				_i,
				_j,
				_data = JSON.parse(_filter_data),
				_mark = new Array(),
				_model = new Array(),
				_model_by_mark = new Array();
			for (var _i in _data) {
				var _dt = new Object;
				if (_data[_i].indexOf(',') !== -1) {
					_dt = _data[_i].split(',');
				} else {
					_dt[_i] = _data[_i];
				}
				for (_j in _dt) {
					if ($('.filter input[name="' + _i + '"][value="' + _dt[_j] + '"]').length > 0) {
						$('.filter input[name="' + _i + '"][value="' + _dt[_j] + '"]').prop('checked', true).change();
					} else if ($('.filter input[name="' + _i + '"]').length > 0 && $('input[name="' + _i + '"]').attr('type') === 'number') {
						$('.filter input[name="' + _i + '"]').val(_dt[_j]) ;
					} else if ($('select[name="' + _i + '"]').length > 0) {
						if(_i === 'mark') {
							_mark.push(_dt[_j]);
						} else if(_i === 'range') {
							_model.push(_dt[_j]);
							_model_by_mark.push($('.filter select[name="' + _i + '"] option[value="'+_dt[_j]+'"]').data('mark'));
						} else {
							$('.filter select[name="' + _i + '"]').multipleSelect("check", _dt[_j]);
							$('.filter select[name="' + _i + '"]').siblings('.js-single-select, .js-double-select').addClass('is_fill').siblings('.js-singleselect__clear').addClass('is_show')
						}
					}
				}
			}

			if (_model.length) {
				$('.filter select[name="range"]').multipleSelect("setSelects", _model);
				$('.filter select[name="range"]').multipleSelect("refresh");
			}
			if(_mark.length) {
				setModelByMark(_mark)
				$('.filter select[name="mark"]').multipleSelect("setSelects", _mark);
				$('.filter select[name="mark"]').multipleSelect("refresh");
			} else if(_model_by_mark.length) {
				_model_by_mark = Array.from(new Set(_model_by_mark));
				setModelByMark(_mark)
				$('.filter select[name="mark"]').multipleSelect("setSelects", _model_by_mark);
				$('.filter select[name="mark"]').multipleSelect("refresh");
			}
		}
		$('.js-filter-form').removeClass('loading')
	}

	function CatalogFilter($, _url) {

		var _view = $('.products .state-mode').find('.is_row').length ? 'list' : 'plate';

		var redirect = false;

		if (empty(_url)) {
			if (document.location.href.indexOf('favourites') != -1) {
				var _url = _lang+'/auto-ajax-favourites';
			} else {
				var _url = _lang+'/auto-ajax';
			}
		} else {
			redirect = true;
		}

		var _data = GetFormData($('.js-filter-form'), 'add');
		console.log(_data)

		for (var i in _data) {
			if (!empty(_data[i])) {
				_url = AddToGet(_url, i + '=' + encodeURI(_data[i]), 'add', 'return');
			}
		}

		_url += CheckSort($);

		if (redirect) {
			document.location.href = _url;
		} else {
			SendAjax(_url, null, function (data) {
				$.when( $('.catalog').empty().append(data)).then(function() {
					$('.lazy').lazy({
						afterLoad: function(element) {
							element.removeAttr('style');
						}
					});
				});

				if(_view == "list") {
					$(".js-product-item").addClass("is_row");
				}
				if(_view == "plate") {
					$(".js-product-item").removeClass("is_row");
				}

				ResoteFavourites($);

				$('.catalog').removeClass('loading');
			}, 'GET', 'html');
		};
	}

	function disableFields(fields, _formid = '.js-estimate-form') {
		fields.forEach(function (item, i, fields) {
			$(_formid).find('select[name="' + item + '"]').siblings('.js-singleselect__clear').removeClass('is_show');
			$(_formid).find('select[name="' + item + '"]').prop('disabled', true).multipleSelect('refresh');
		});
	}

	function GetData($, _dt, _formid = '.js-estimate-form') {
		var _i, _j;
		$(_formid).addClass('loading');
		jQuery.ajax({
			url: _lang+'/estimate-ajax',
			dataType: 'json',
			type: 'POST',
			data: _dt,
			async: true,
			success: function (data) {
				$(_formid).find('select[name="' + _dt['data']['name'] + '"]').prop('disabled', true).empty();
				for (_i in data) {
					if (data[_i] !== '') {
						if (_dt['data']['name'] === 'mark' || _dt['data']['name'] === 'model')
							$(_formid).find('select[name="' + _dt['data']['name'] + '"]').append('<option data-val="' + _i + '" value="' + data[_i].text + '">' + data[_i].text + '</option>')
						else
							$(_formid).find('select[name="' + _dt['data']['name'] + '"]').append('<option data-val="' + data[_i].value + '" value="' + data[_i].text + '">' + data[_i].text + '</option>');
					}
				}
				$(_formid).find('select[name="' + _dt['data']['name'] + '"]').prop('disabled', false).multipleSelect('refresh');
				setTimeout(function(){
					$(_formid).removeClass('loading');
				}, 2000)
			},
			error: function (data) {
				$(_formid).removeClass('loading');
				console.log(data);
			}
		});
	}

	function CheckSort($) {
		var _catalog_sort = $('select[name="catalog-sort"]').multipleSelect('getSelects');
		var _sort_get = '';
		if (!empty(_catalog_sort)) {
			_sort_get = '&catalog-sort=' + _catalog_sort.join();
		}
		return _sort_get;
	}

	function normalizeNewsHeight(obj) {
		if (empty(obj)) {
			return;
		};
        obj.find('.js-blog-item .js-blog-body').height('auto');
		var h = 0;
		obj.find('.js-blog-item').each(function () {
			var _h = parseFloat($('.js-blog-body', this).height());
			h = _h > h ? _h : h; 
		});
		obj.find('.js-blog-item .js-blog-body').height(h);
	}

    function initCaptcha() {
        window.grecaptcha.ready(function () {
            window.grecaptcha.execute('6LdIjoEfAAAAACFigswi8gJYjg0leH0OxjEVptPC').then(function (token) {
                let recaptcha_inputs = document.querySelectorAll('input[name=recaptcha_response]');
                for (let input of recaptcha_inputs) {
                    input.value = token;
                }
            });
        });
    }

	function initMainSlider() {
		const $slider = $(".js-slider");
		const arrows = !!$slider.length && $slider.data("arrows").split(",")
    
		const buttonsTemplate = {
			prev : `<button type="button" class="slick-prev"><svg><use xlink:href="${arrows[0]}"></use></svg></button>`,
			next: `<button type="button" class="slick-next"><svg><use xlink:href="${arrows[1]}"></use></svg></button>`
		}

		$slider.slick({
			arrows: true,
			dots: false,
			slidesToShow: 1,
			prevArrow: buttonsTemplate.prev,
			nextArrow: buttonsTemplate.next,
		}) 
	}

	$(document).ready(function(){
		if (!empty($('input[name="time"]'))) {
			$('input[name="time"]').timepicker();
		}

		if (!empty(localStorage.getItem('anchor-link'))) {
			var anchor = localStorage.getItem('anchor-link');
			localStorage.removeItem('anchor-link');
			ScrollToElement($, $(anchor), 300, -50);
		}

		if (!empty($('input[name="date"]'))) {
			$('input[name="date"]').each(function(){	
				var dateField = $(this);
				var datepicker = new Pikaday({
					field: dateField[0],
					firstDay: 1,
					minDate: new Date(),
					onSelect: function (date) {
						let year = date.getFullYear();
						let month = (date.getMonth() + 1).toString();
						month = (month.length == 1)?pad(month,2):month;
						let day = date.getDate().toString();
						day = (day.length == 1)?pad(day,2):day;
						field.value = day+'.'+month+'.'+year;
					},
					i18n: _i18n
				});
			})
		}
		
		initCaptcha();
		setInterval(initCaptcha,120000);

		if (!empty($('.js-news-slider'))) {
			normalizeNewsHeight($('.js-news-slider'));
		}

		if (!empty($('.js-blog-grid'))) {
			normalizeNewsHeight($('.js-blog-grid'));
		}

		initMainSlider();
		mobilePaginationHide();
		ResoteFavourites($);

		CalcLeasing(0);
		CalcLeasing(1);

		if($(window).width() < 768) {
			$('.js-filter').removeClass('is_open').find('#1-switch').prop('checked', false);
		}

		$(window).scroll(function(){
			if($(this).scrollTop() > 500) {
				$('.scroll-top').fadeIn(300)
			} else {
				$('.scroll-top').fadeOut(300)
			}
		})

		$(window).on('resize', function(){
			if (!empty($('.js-blog-grid'))) {
				normalizeNewsHeight($('.js-blog-grid'));
			}
		});

		$('.js-reviews-modal').magnificPopup({
			type: 'inline',
			removalDelay: 300,
			mainClass: 'mfp-modal-link',
			callbacks: {
				open: function() {
					var ref = $(this.currItem.el);
					var self = $(this.content)
					console.log(ref)
					review = ref.closest('.reviews__slider-item').html();
					$('#ReviewModal .modal-content').html(review)
				},
				close: function() {
					$('#ReviewModal .modal-content').empty()
				}
			}
		})

		$('.mfp-modal-link').magnificPopup({
			type: 'inline',
			removalDelay: 300,
			mainClass: 'mfp-modal-link',
			callbacks: {
				open: function() {
					var ref = $(this.currItem.el);
					var self = $(this.content)
					if(self.attr('id') === 'SelectCarModal') {
						if(!empty(ref.data('dealer'))) {
							self.find('input[name="dealer"]').val(ref.data('dealer'))
						}
						if(!empty(ref.data('car'))) {
							self.find('input[name="auto"]').val(ref.data('car'))
						}
					}
					if(self.attr('id') === 'AppointmentModal') {
						if(!empty(ref.data('dealer'))) {
							self.find('select[name="dealer"]').multipleSelect("setSelects", [ref.data('dealer')])
						}
					}
				},
				close: function() {
					var self = $(this.content)
					self.find('.has-error').removeClass('has-error')
					if(self.attr('id') === 'SelectCarModal') {
						self.find('input[name="dealer"]').val('')
						self.find('input[name="auto"]').val('')
					}
					if(self.attr('id') === 'AppointmentModal') {
						self.find('select[name="dealer"]').multipleSelect("setSelects", [])
					}
				}
			}
		});

		$(document).on('click', '.modal .close', function(e) {
			e.preventDefault();
			$.magnificPopup.close();
		});

		$('.js-anchor-link').click(function(){
			var el = $(this).data('anchor');
			ScrollToElement($, $(el), 300, -150);
		})

		$('.calculator-leasing input').on('change', function(e) {
			var type = $('form.active').children('input[name="type"]').val();
			CalcLeasing(type);
		});

		$('input[name="phone"]').mask('+371 99 999 999');

		$('form input[name="token"]').each(function () {
			if (CSRF)
				$(this).val(CSRF);
		});

		$(document).on('submit', 'form', function (event) {

			var _t = $(this),
				_thx = '#' + $(_t).attr('id') + 'Thnx';

			if (!_t.hasClass('not_ajax')) {
				event.preventDefault();
				if (FormValidate($, _t, false, 'has-error')) {
					_t.addClass('loading');
					SendFormAjax($, _t, function (data) {
						_t.removeClass('loading');
						_t.find('input:not([type="checkbox"])').val('')
						_t.find('input[type="checkbox"]').prop('checked', false)
						_t.find('textarea').val('')
						_t.find('.js-singleselect__clear').click();
						if (!empty($(_thx))) {
							$.magnificPopup.open({
								items: {
									src: _thx
								},
								type: 'inline',
							});
						}
					}, 'html');
				}
			} else {
				if (!FormValidate($, _t, false, 'has-error')) {
					event.preventDefault();
				}
			}
		});

		$(document).on('click', '.add_to_favourites', function () {
			var _t = $(this);
			_t.toggleClass('active');
			AddToFavourites($, $(this).attr('data-id'));
		});

		$(document).on('click', '.js-select-sort li', function (e) {
			if ($(this).closest('.js-single-select').hasClass('is_open')) return;
			$('.catalog').addClass('loading');
			CatalogFilter($);
		});

		$(window).on('load', function () {
			GetData($, {
				'action': 'select',
				'data': {
					'type': 'mark',
					'name': 'mark',
				}
			});
			disableFields(['model', 'year', 'body_type', 'transmission']);
		});

		$('.js-estimate-form').on('click', '.js-singleselect__clear', function(){
			var _formid = '#' + $(this).closest('form').attr('id');
			var name = $(this).data('name');
			if (name === 'mark') {
				disableFields(['model', 'year', 'body_type', 'transmission'], _formid);
			} else if (name === 'model') {
				disableFields(['year', 'body_type', 'transmission'], _formid);
			} else if (name === 'year') {
				disableFields(['body_type', 'transmission'], _formid);
			} else if (name === 'body_type') {
				disableFields(['transmission'], _formid);
			} else if (name === 'transmission') {

			}
		})

		$('.js-estimate-form').on('click', '.js-single-select li', function (e) {
			if ($(this).closest('.js-single-select').hasClass('is_open')) return;
			var _formid = '#' + $(this).closest('form').attr('id');
			var name = $(this).closest('.js-single-select').siblings('select').attr('name');
			var selectedVal = $('select[name="'+name+'"] option:selected', _formid).data('val');
			if (name === 'mark') {
				GetData($, {
					'action': 'select',
					'data': {
						'type': 'model',
						'name': 'model',
						'mark': selectedVal,
					}
				}, _formid);
				disableFields(['year', 'body_type', 'transmission'], _formid);
			} else if (name === 'model') {
				GetData($, {
					'action': 'select',
					'data': {
						'type': 'year',
						'name': 'year',
						'mark': $('select[name="mark"] option:selected', _formid).data('val'),
						'model': selectedVal,
					}
				}, _formid);
				disableFields(['body_type', 'transmission'], _formid);
			} else if (name === 'year') {
				GetData($, {
					'action': 'select',
					'data': {
						'type': 'body_type',
						'name': 'body_type',
						'mark': $('select[name="mark"] option:selected', _formid).data('val'),
						'model': $('select[name="model"] option:selected', _formid).data('val'),
						'year': selectedVal,
					}
				}, _formid);
				disableFields(['transmission'], _formid);
			} else if (name === 'body_type') {
				GetData($, {
					'action': 'select',
					'data': {
						'type': 'transmission',
						'name': 'transmission',
						'mark': $('select[name="mark"] option:selected', _formid).data('val'),
						'model': $('select[name="model"] option:selected', _formid).data('val'),
						'year': $('select[name="year"] option:selected', _formid).data('val'),
						'body_type': selectedVal,
					}
				}, _formid);		
			} else if (name === 'transmission') {
				
			}
		});

		$('.catalog').on('click', '.js-catalog-pagination-item a, .js-show-more', function (e) {

			var _src = $(this);

			e.preventDefault();
			e.stopPropagation();

			if (_src.hasClass('active')) {return}

			var _view = $('.products .state-mode').find('.is_row').length ? 'list' : 'plate';

			if (_src.attr('href').indexOf('favourites') != -1) {
				var url = _src.attr('href').replace(/[^\?]*(?=\?)?/, _lang+'/auto-ajax-favourites');
			} else {
				var url = _src.attr('href').replace(/[^\?]*(?=\?)?/, _lang+'/auto-ajax');
			}

			url += CheckSort($);

			$('.catalog').addClass('loading');

			SendAjax(url, null, function (data) {
				if(_src.hasClass('js-show-more')) {
					var _scrollTo = $('.catalog').offset().top + $('.catalog').height() - 200;
					$('.pagination').remove();
					 $.when($('.catalog').append(data)).then(function() {
						$('.catalog').find('.js-product-title').elementHeight('reInit');
						$('.catalog').find('.js-products-props').elementHeight('reInit');
					});
				} else {
					var _scrollTo = $('.catalog').offset().top - 200;
					$.when($('.catalog').empty().append(data)).then(function() {
						$('.catalog').find('.js-product-title').elementHeight('reInit');
						$('.catalog').find('.js-products-props').elementHeight('reInit');
					});
				}

				ResoteFavourites($);

				if(_view == "list") {
					$(".js-product-item").addClass("is_row");
				}
				if(_view == "plate") {
					$(".js-product-item").removeClass("is_row");
				}

				$('.catalog').removeClass('loading');

				$('body,html').animate({
					scrollTop: _scrollTo
				}, 400);
			}, 'GET', 'html');
		});

		$('.blog-grid, .blog-intro').on('click', '.js-blog-pagination-item a, .js-blog-sort li, .js-blog-sort-clear, .js-show-more', function (e) {
			var _src = $(this);
			if(_src.closest('.pagination').length) {
				if (_src.hasClass('active')) return;
				var url = _src.attr('href').replace(/[^\?]*(?=\?)?/, _lang+'/news-ajax');
			} else {
				if (_src.closest('.js-single-select').hasClass('is_open')) return;
				var url = _lang+'/news-ajax';
			}

			e.preventDefault();
			e.stopPropagation();

			var _news_filter = $('select[name="news-filter"]').multipleSelect('getSelects');
			var _filter_get = (!empty(_news_filter))?'&news-filter=' + _news_filter.join():'';

			url += _filter_get;

			$('.blog-grid').addClass('loading');

			SendAjax(url, null, function (data) {
				if(_src.hasClass('js-show-more')) {
					$('.pagination').remove();
					var _scrollTo = $('.js-blog-grid').offset().top + $('.js-blog-grid').height() - 200;
					$.when($('.js-blog-grid').append(data)).then(function() {
						normalizeNewsHeight($('.js-blog-grid'));
					});
				} else {
					var _scrollTo = $('.js-blog-grid').offset().top - 200;
					$.when($('.js-blog-grid').empty().append(data)).then(function() {
						normalizeNewsHeight($('.js-blog-grid'));
					});
				}
				$('.blog-grid').removeClass('loading');

				$('body,html').animate({
					scrollTop: _scrollTo
				}, 400);
			}, 'GET', 'html');
		});

		if (!empty($('.filter'))) {
			let filterInt = setInterval(function(){
				if(!empty($('.filter select + .js-single-select'))) {
					RestoreFilterData($);
					clearInterval(filterInt);
				}
			}, 1000)
		}

		$(document).on('click', '.js-filter-search', function (e) {
			e.preventDefault();
			e.stopPropagation();
			$('.catalog').addClass('loading');
			var _url = $(this).data('url');
			CatalogFilter($, _url);
		})

		$('.filter').on('click', 'select[name="mark"] + .js-multi-select li', function () {
			var mark = $('.filter select[name="mark"]').val();
			$('.filter .js-mark-check').show(0);
			if(mark.length) {
				$('.filter .js-mark-check').hide(0);
				mark.forEach(function(val, i, mark){
					$('.filter .js-mark-' + val).show(0);
				})
			}
		});

		$('.sticky-menu-wrap a.more-buttons').click(function(e){
			e.preventDefault();
			$('.sticky-menu-wrap .add-phones').fadeOut(50)
			$('.sticky-menu-wrap .add-buttons').fadeToggle(200)
		})

		$('.sticky-menu-wrap a.more-phones').click(function(e){
			e.preventDefault();
			$('.sticky-menu-wrap .add-phones').fadeToggle(200)
			$('.sticky-menu-wrap .add-buttons').fadeOut(50)
		})

		$('.js-related-more').click(function(e){
			e.preventDefault();
			var url = $(this).parent('a').attr('href')
			localStorage.setItem('anchor-link', $(this).data('anchor'))
			window.location.href = url;
		})
	})
})
