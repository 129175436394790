import { initElements } from "./initElements";

$(() => {
    const mediaQueriesMax = width => window.matchMedia(`(max-width: ${width}px)`).matches
    const methods = {
        isSingle: function() {
            const $element = $(this);
            $.each($element, function() {
                $(this).removeAttr("style")
                $(this).removeAttr("data-item-row");
            })
        },

        reInit: initElements,

        inSliderInit: function() {            
            const elementsHeight = [];
            const $element = $(this) 
            $.each($element, function() {
                $(this).removeAttr("style")
                $(this).removeAttr("data-item-row");
                if (mediaQueriesMax(768)) return
                const height = $(this).height() 
                elementsHeight.push(height)
            })

            const maxHeight = Math.max(...elementsHeight)
            $element.height(maxHeight) 
        }
    }

    $.fn.elementHeight = function(method) {
        const dataSlider = this.closest(".products__inner").attr("data-slider")

        if (!!dataSlider) {
            return methods.inSliderInit.call(this)
        }

        if (!!methods[method]) {
            return methods[method].call(this)
        }
         else {
            initElements.call(this)
        }
    }
})