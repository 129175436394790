$(() => {
    const $productsTitle = $(".js-product-title");
    const $productsProps = $(".js-products-props");

    $productsTitle.elementHeight("init")
    $productsProps.elementHeight("init")

    $(window).on("resize", function() {
        $productsTitle.elementHeight("reInit")
        $productsProps.elementHeight("reInit")
    })
})


