$(()=> {
  const $accordionHead = $(".js-accordion-head");
  const $accordionBody = $accordionHead.next();
  
  initDefaultState()
  
  $accordionHead.on("click", function() {
    const that = $(this);
    $accordionBody.css("max-height", 0)
    const siblingBody = that.next()
    $accordionHead.parent().removeClass("active");
    that.parent().addClass("active");
    siblingBody.css("max-height", siblingBody[0].scrollHeight + "px")
  })

  function initDefaultState () {
    
    if(!!$accordionHead.length) {
      $accordionHead.eq(0).parent().addClass("active");
      const firstTab = $accordionHead.eq(0).next()
      firstTab.css("max-height", firstTab[0].scrollHeight + "px");  
    }
  }
})

