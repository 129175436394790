$(()=> {
    const $modelDataTab = $(".js-insert-tab-tab");
    const $modelDataContent = $(".js-insert-tab-content");
     
    $modelDataTab.on("click", function(e) {
        switchedTabs($modelDataTab, $(this), $modelDataContent)
    })

    const $equipmentSelect = $(".js-equipment-select");
    const $equipmentBody = $(".js-equipment-body");

    $equipmentSelect.on("click", function() {
        switchedTabs($equipmentSelect, $(this), $equipmentBody)
    })
})

function switchedTabs(tab, that, elementContent) {
    const idx = tab.index(that)
    tab.removeClass("active");

    that.addClass("active");

    if(elementContent) {
        elementContent.removeClass("active");
        elementContent.eq(idx).addClass("active")
    }
}