$(() => {
    const $nav = $(".js-nav");
    
    const linkText = $nav.attr("data-linkText");
    const linkTextAll = $nav.attr("data-linkTextAll");

    $nav.find('ul.navmenu').flexMenu({
        showOnHover: true,
        linkText: linkText,
        linkTextAll: linkTextAll,
        popupClass: 'nav__more-dropdown',
    });
})