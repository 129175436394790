$(()=> {
    const $inputFile = $(".js-input-file");
    // let fileName 
    $inputFile.on("change", (e)=> {
        const target = $(e.target)
        const title = target.data("title")
        const nextElem = target.next().find(".js-input-file-text")
        const file = target.val().split("\\").pop()
        
        if(file) {
            // fileName = file
            return nextElem.text(file)
        }
        nextElem.text(title)
    })
})