$(function() {
    const $window =  $(window)
    const $headerFixed = $(".js-header__fixed")
    let fixed = false
    window.updateFlexMenu()
    $window.on("scroll load", function(){
        const scrollTop = $(this).scrollTop();
        const heightTop = $headerFixed.parent().parent().height();

        if (scrollTop >= heightTop && !fixed) {
            $headerFixed.addClass('fixed-header');
            window.updateFlexMenu()
            fixed = true
        }
        else if( scrollTop <= heightTop && fixed ) {
            $headerFixed.removeClass('fixed-header');
            fixed = false
        }
    });
})