const mediaQueriesMin = width => window.matchMedia(`(min-width: ${width}px)`).matches
const mediaQueriesMax = width => window.matchMedia(`(max-width: ${width}px)`).matches

export function initElements() {
    const $element = $(this);
    $element.height("auto");
    $element.removeAttr("data-item-row");
    const elementsHeight = [];
    let count = 0;
    let rowIndex = 0;
    let gap = 0;
    let arrSize = 0;

    if(mediaQueriesMin(1600)) {            
        count = 4;
        arrSize = 4;
        gap = 4;
    }

    if(mediaQueriesMin(1100) && mediaQueriesMax(1600)) {
        count = 3;
        arrSize = 3;
        gap = 3;
    }

    if(mediaQueriesMin(768) && mediaQueriesMax(1100)) {
        count = 2;
        arrSize = 2;
        gap = 2;
    }


    if(mediaQueriesMin(0) && mediaQueriesMax(768)) {
        count = 1;
        arrSize = 1;
        gap = 1;
    }

    $.each($element, function(i) {
        const height = $(this).height() 
        elementsHeight.push(height)
        if( i === count) {
            count += gap;
            rowIndex += 1
        }
        $(this).attr("data-item-row", rowIndex)
    })

    const elementsRow  = elementsHeight.reduce((final, curr) => {
        if (final[final.length - 1].length === arrSize) {
            final.push([])
        }
        final[final.length - 1].push(curr)
        return final
    }, [[]])

    $.each($element, function() {
        const currentRow = $(this).attr("data-item-row")
        const maxHeight = Math.max(...elementsRow[currentRow])
        
        const className = $(this).attr("class").split(" ")[0]
        $(`.${className}[data-item-row="${currentRow}"]`).height(maxHeight)
    })
}