$(()=> {
    const $calculatorLeasingTab = $(".js-calculator-leasing__tab");
    const $calculatorLeasingContent = $(".js-calculator-leasing__content");

    let memoization = null

    $calculatorLeasingTab.on("click", function() {
        const that = $(this);
        const idx = $calculatorLeasingTab.index(that)
        const tab = that.data("tab")

        if(memoization === tab) return

        $calculatorLeasingTab.removeClass("active")
        that.addClass("active")
        $calculatorLeasingContent.removeClass("active")
        $calculatorLeasingContent.eq(idx).addClass("active")

        memoization = tab
    })
})