$(()=> {
  
    // Favorite Btn

    const $favoriteBtn = $(".js-favorite-btn");

    $favoriteBtn.on("click", function() {
      $(this).toggleClass("active");
    })

    // Recurring Carousel
    function initSlider() {
      const $slider = $(".js-slider-slick");

      const arrows = !!$slider.length && $slider.data("arrows").split(",")
    
      const buttonsTemplate = {
          prev : 
          `
              <button type="button" class="slick-prev">
                  <svg>
                      <use xlink:href="${arrows[0]}"></use>
                  </svg>
              </button>
          `,

          next: 
          `
              <button type="button" class="slick-next">
                  <svg>
                      <use xlink:href="${arrows[1]}"></use>
                  </svg>
              </button>
          `
      }

      $slider.on('init', function(event, slick) {
          offsetDots(0, $slider);
      });

      $slider.on("beforeChange", (e, slick, prevIdx, currentIdx)=> {          
          offsetDots(currentIdx, $slider)
      })

      $slider.slick({
          slidesToShow: 3,
          prevArrow: buttonsTemplate.prev,
          nextArrow: buttonsTemplate.next,
          responsive: [
              {
                breakpoint: 1210,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 2, 
                  arrows: false,    
                  dots: true,
                }
              },
              {
                breakpoint: 800,
                settings: {
                  adaptiveHeight: true,
                  arrows: false,
                  dots: true,
                  slidesToShow: 1,
                }
              },
          ],
      }) 
    }
    
    initSlider()

    // Magnific Popup

    $('.js-popup-link').magnificPopup({
		src: $(this).attr('data-mfp-src'),
		type: 'inline',
        callbacks: {
            close: function() {

            },
            open: function() {
                
            }
        },
	})

    $('.js-modal-close').on('click', ()=> $.magnificPopup.close())

})


export function offsetDots(slideNum, $slider) {
  const $slickDots = $slider.find('.slick-dots'),
    numDots = $slickDots.find('li').length,
    dotWidth = $slickDots.find('li:first').outerWidth(true),
    offset = dotWidth * slideNum;
  

  $slickDots.css("-webkit-transform", `translateX(${offset * -1 - Math.ceil(dotWidth / 2)}px)`);
  

  $slickDots.find('li').removeClass('slick-secondary');
 
  if (slideNum > 0) {
    $slickDots.find('li:nth-of-type('+slideNum+')').addClass('slick-secondary');
  }

  if (slideNum < numDots - 1) {
    $slickDots.find('li:nth-of-type('+(slideNum + 2)+')').addClass('slick-secondary');
  }
}
